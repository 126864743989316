@import "./styles/variables.scss";
@import "./styles/global-classes.scss";

body {
    background-color: $background-primary-color !important;
    overflow: scroll !important;
}

* {
    box-sizing: border-box !important;
    scrollbar-width: thin;
}

button {
    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    &:active {
        transform: scale(0.95);
    }
    &:focus-visible {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        transform: none;
        box-shadow: none;
    }
}

input {
    caret-color: $caret-color !important;
    &:focus-visible {
        outline: solid 1px $border-primary-color;
    }
}

textarea {
    caret-color: $caret-color !important;
    resize: none !important;
    &:focus-visible {
        outline: solid 1px $border-primary-color !important;
    }
}

select {
    &:focus-visible {
        outline: none;
    }
}

label {
    font-weight: 600 !important;
    color: $text-primary-color !important;
}

h1 {
    text-align: center !important;
    color: $text-primary-color !important;
}

[class*="fa-"] {
    color: $text-primary-color;
}

.fa-trash {
    color: $danger-color !important;
}

div[class*="indicatorContainer"] {
    color: $text-primary-color !important;
    padding: 0px 8px !important;
}

div[class*="multiValue"] {
    border-radius: 50rem !important;
}

div[class*="MultiValueRemove"] {
    color: $text-primary-color !important;
}

div[class*="ValueContainer"] {
    padding: 0px 8px !important;
}

span[class*="Text-A11yText"] + div[class*="control"] {
    display: flex !important;
    align-items: start !important;
}

input[readonly] {
    background-color: $background-disabled-readonly-color;
}

select[disabled] {
    background-color: $background-disabled-readonly-color;
}
