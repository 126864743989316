@import "./variables.scss";

.text-primary-color {
    color: $text-primary-color !important;
}

.text-secondary-color {
    color: $text-secondary-color !important;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.danger-color {
    color: #ff0000 !important;
}

.caret-color {
    caret-color: $caret-color !important;
}

.separator {
    color: $separator-color !important;
    background-color: $separator-color !important;
    height: 2px !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    align-self: center !important;
}

.border-right {
    border-right: 1px solid $border-primary-color !important;
}

.border-primary-color {
    border-color: $text-primary-color !important;
}

.background-primary-color {
    background-color: $background-primary-color !important;
}

.background-secondary-color {
    background-color: $background-secondary-color !important;
}

.background-button-color {
    background-color: $background-button-color !important;
}

.background-confirm-button-color {
    background-color: $background-confirm-button-color !important;
}

.background-cancel-button-color {
    background-color: $background-cancel-button-color !important;
}

.align-self-center {
    align-self: center !important;
}

.min-width-navbar {
    min-width: 300px !important;
}

.max-width-card {
    max-width: 50vw !important;
}

.max-width-link-relations {
    max-width: 5rem !important;
}

.max-width-small {
    max-width: 350px !important;
}

.max-width-medium {
    max-width: 500px !important;
}

.max-width-semi-large {
    max-width: 650px !important;
}

.max-width-large {
    max-width: 800px !important;
}

.max-width-input {
    max-width: 310px !important;
}

.max-width-image {
    max-width: 150px !important;
}

.max-width-image-nav {
    max-width: 80px !important;
}

.min-height-100vh {
    min-height: 100vh !important;
}

.height-large {
    height: 100px !important;
}

.width-input {
    width: 310px !important;
}

.width-large {
    width: 800px !important;
}

.w-95 {
    width: 95% !important;
}

.vh-50 {
    height: 50vh !important;
}

.top-1 {
    top: 1rem !important;
}

.left-1 {
    left: 1rem !important;
}

.border-w-2 {
    border-width: 2px !important;
}

.border-w-5 {
    border-width: 5px !important;
}

.border-w-10 {
    border-width: 10px !important;
}

.border-solid {
    border-style: solid !important;
}

.border-primary-color {
    border-color: $border-primary-color !important;
}

.border-secondary-color {
    border-color: $border-secondary-color !important;
}

.border-input {
    border-width: 1px;
    border-style: solid;
}

.table-border-bottom {
    border-bottom: 1px solid $border-primary-color !important;
}

.table-border-right {
    border-right: 1px solid $border-primary-color !important;
}

.vertical-tx-top {
    vertical-align: text-top !important;
}

.vertical-bottom {
    vertical-align: bottom !important;
}

.bottom-1 {
    bottom: 1rem !important;
}

.right-1 {
    right: 1rem !important;
}

.left-1 {
    left: 1rem !important;
}

.fs-7 {
    font-size: 0.875rem !important;
}

.lh-3 {
    line-height: 1.5rem !important;
}

.py-0-157 {
    padding-top: 0.157rem !important;
    padding-bottom: 0.157rem !important;
}

.n-mt-1 {
    margin-top: -1rem !important;
}

.n-z-1 {
    z-index: -1 !important;
}

.list-style-none {
    list-style: none !important;
}

.circular-button {
    width: 30px;
    height: 30px;
}

.flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.flex-start {
    display: flex;
    justify-content: flex-start !important;
    align-items: start !important;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.translate-middle-y-15 {
    transform: translateY(15%);
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}

.is-paid {
    color: $is-paid-color !important;
}

.is-not-paid {
    color: $is-not-paid-color !important;
}

.custom-button {
    &:hover {
        transform: scale(1.1);
        box-shadow: none;
    }
    &:active {
        transform: scale(0.95);
    }
    &:focus-visible {
        outline: none !important;
    }
}

.no-button-effects {
    &:hover {
        transform: none;
        box-shadow: none;
    }
    &:active {
        transform: none;
    }
}

.link-decoration-effect {
    text-decoration: none !important;
    &:hover {
        text-decoration: underline !important;
        text-decoration-color: $text-decoration-color !important;
    }
}

.go-to-button {
    right: 3rem;
    bottom: 2rem;
}

.close-button-modal {
    top: 0px;
    right: 10px;
}

.nav-button {
    transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.5s ease;

    &.open {
        transform: rotate(180deg);
    }
}
