$background-primary-color: #fff1f8;
$background-secondary-color: #fdc1dd;
$background-button-color: #b75c87;
$background-confirm-button-color: #71eb75;
$background-cancel-button-color: #eb7871;
$text-primary-color: #b75c87;
$text-secondary-color: #fff;
$border-primary-color: #b75c87;
$border-secondary-color: #fdc1dd;
$caret-color: #b75c87;
$separator-color: #b75c87;
$danger-color: #ff0000;
$text-decoration-color: #b75c87;
$outline-color: #b75c87;
$is-paid-color: #71eb75;
$is-not-paid-color: #a3a3a3;
$background-disabled-readonly-color: #f2f2f2;

:root {
    --background-primary-color: #fff1f8;
    --background-secondary-color: #fdc1dd;
    --border-primary-color: #b75c87;
}
